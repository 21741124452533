import { Link } from '@inertiajs/react';
import PageWidthConstraint from 'Components/PageWidthConstraint.jsx';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import route from 'ziggy-js';

const Footer = () => {
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator?.standalone === true) {
      setIsStandalone(true);
    }
  }, []);

  if (isStandalone) {
    return null;
  }

  return (
    <div className="mt-20 bg-gray-700 text-white">
      <PageWidthConstraint className="py-12">
        <div className="flex flex-wrap gap-x-8 gap-y-2">
          <Link>Home</Link>
          <a href={route('privacy')}>Privacy Policy</a>
          <a href={route('terms')}>Terms</a>
          <a href={route('home')}>About</a>
          <a href={route('media_standards')}>Media Standards</a>
          <a href={route('team')}>Team</a>
          <a href={route('advertising')}>Advertising</a>
          <a href={route('tip_line')}>Tip Line</a>
        </div>
        <div className="mt-4 text-xs opacity-50">&copy; Hawke's Bay App {format(new Date(), 'yyyy')} | Engage Digital Media Limited</div>
      </PageWidthConstraint>
    </div>
  );
};

export default Footer;
