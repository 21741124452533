import { usePage } from '@inertiajs/react';
import Footer from 'Layouts/_partials/Footer.jsx';
import Header from 'Layouts/_partials/Header.jsx';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import useAdStore from 'Support/useAdStore.js';

const MainLayout = ({ children }) => {
  const { clear } = useAdStore();

  useEffect(() => {
    clear();
  }, [usePage().url]);

  return (
    <div className="flex min-h-screen flex-col font-sans font-medium text-black">
      <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="bottom-right" />

      <Header />
      <div className="grow">{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
