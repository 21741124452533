import { createInertiaApp, Head } from '@inertiajs/react';
import Loader from 'Components/Loader.jsx';
import * as React from 'react';
import { createElement, StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import { FormErrorContextProvider, InitialApplicationContext } from 'Support/Contexts';
import 'Support/yupExtensions';
import { LoadingProvider } from 'Support/Contexts/LoadingContext.jsx';
import { pushNotificationHelper } from 'Support/pushNotificationHelper';

export const initInertiaApp = ({ resolve: resolveComponent, layout: MainLayout, progress }) => {
  return createInertiaApp({
    progress: progress === undefined ? { showSpinner: true } : progress,
    page: window.inertiaInitialPageData,

    resolve(name) {
      return resolveComponent(name).catch((e) => {
        throw new Error(`Inertia failed to load the page component for "${name}".`, {
          cause: e,
        });
      });
    },

    setup({ el, App, props }) {
      const initialApplication = { application: props.initialPage.props.application };

      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={initialApplication}>
            <App {...props}>
              {({ key, props, Component }) => {
                const componentLayout = Component.layout;
                const page = createElement(Component, { key, ...props });

                const defaultLayout = (children) => (
                  <MainLayout children={children} site_name={page.props.application.site_name} production={page.props.application.production} />
                );

                useEffect(() => {
                  pushNotificationHelper().catch((err) => {});
                }, []);

                useEffect(() => {
                  if (Array.isArray(page.props.flash)) {
                    page.props.flash.forEach(({ type, message }) => {
                      toast(message, { type });
                    });
                  }
                }, [JSON.stringify(page.props.flash)]);

                let children;

                if (Array.isArray(componentLayout)) {
                  children = componentLayout
                    .concat(page)
                    .reverse()
                    .reduce((children, Layout) => createElement(Layout, { children }));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page, defaultLayout);
                } else {
                  children = defaultLayout(page);
                }

                return (
                  <FormErrorContextProvider>
                    <Head>
                      <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                    </Head>
                    <LoadingProvider>
                      <Loader />
                      {children}
                    </LoadingProvider>
                  </FormErrorContextProvider>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>,
      );
    },
  });
};
