const XIcon = () => (
  <svg
    version="1.0"
    style={{ height: '16px', width: '16px' }}
    xmlns="http://www.w3.org/2000/svg"
    width="488.000000pt"
    height="459.000000pt"
    viewBox="0 0 488.000000 459.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,459.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M14 4579 c-2 -3 160 -224 359 -490 199 -266 622 -832 940 -1257 l578
-773 -37 -42 c-21 -23 -446 -482 -944 -1020 -498 -539 -907 -983 -908 -988 -2
-5 85 -9 210 -8 l213 1 70 77 c39 42 401 433 805 870 404 437 746 807 760 823
l25 29 200 -268 c110 -147 413 -553 673 -901 l474 -632 725 0 c603 0 724 2
720 13 -3 8 -187 256 -409 553 -222 296 -476 636 -564 754 -89 118 -350 469
-582 778 -232 310 -422 568 -422 572 0 7 1226 1338 1643 1783 l119 127 -219 0
-218 -1 -255 -276 c-140 -152 -483 -524 -763 -825 l-507 -548 -615 822 -616
823 -725 5 c-399 3 -728 2 -730 -1z m1718 -886 c237 -318 603 -807 813 -1088
210 -280 676 -904 1036 -1385 360 -482 658 -881 663 -888 6 -9 -65 -12 -324
-12 l-331 0 -656 878 c-1514 2023 -2278 3045 -2287 3060 -6 9 62 12 323 12
l331 0 432 -577z"
      />
    </g>
  </svg>
);

export default XIcon;
