import { usePage } from '@inertiajs/react';
import { mdiFacebook, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import XIcon from 'Components/Icons/XIcon.jsx';
import PageWidthConstraint from 'Components/PageWidthConstraint.jsx';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { pushNotificationHelper } from 'Support/pushNotificationHelper';
import route from 'ziggy-js';

const Header = () => {
  const { cdn_url } = usePage().props.application;
  const [isStandalone, setIsStandalone] = useState(false);

  const [notificationsEnabled, setNotificationsEnabled] = useState(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications');
      return false;
    }

    return Notification.permission === 'granted';
  });

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator?.standalone === true) {
      setIsStandalone(true);
    }
  }, []);

  return (
    <>
      <div className={cls(isStandalone ? 'hidden' : 'bg-gray-900 py-2 text-xs text-white')}>
        <PageWidthConstraint className="flex flex-wrap items-center justify-between gap-2">
          <div className="flex items-center gap-4">
            <div>
              <a href={route('home')}>About</a>
            </div>

            <div>
              <a href={route('team')}>Team</a>
            </div>

            <div>
              <a href={route('advertising')}>Advertising</a>
            </div>

            <div>
              <a href={route('tip_line')}>Tip Line</a>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <a href="https://facebook.com/hawkesbayapp" target="_blank">
              <Icon path={mdiFacebook} size={0.8} />
            </a>

            <a href="https://twitter.com/HawkesBayApp" target="_blank">
              <XIcon />
            </a>

            <a href="https://instagram.com/hawkesbayapp" target="_blank">
              <Icon path={mdiInstagram} size={0.8} />
            </a>
          </div>
        </PageWidthConstraint>
      </div>

      <div className="sticky top-0 z-50 bg-white shadow-lg shadow-black/5">
        <PageWidthConstraint className="flex items-center justify-between gap-4">
          <a href={cdn_url}>
            <img src="/static/img/logo.png" alt="logo" className="my-2" />
          </a>

          <div className="flex h-full items-center">{format(new Date(), 'iiii do MMMM')}</div>
        </PageWidthConstraint>
      </div>
      {!notificationsEnabled && (
        <div className="w-full bg-gray-900 p-4 text-sm text-white">
          Want to be notified of the latest news?
          <button
            className="pl-1 text-primary"
            type="button"
            onClick={() => {
              pushNotificationHelper()
                .then(() => {
                  setNotificationsEnabled(true);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Click Here
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
