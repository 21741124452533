import React, { useContext } from 'react';
import { LoadingContext } from 'Support/Contexts/LoadingContext.jsx';

const Loading = () => {
  const { isLoading } = useContext(LoadingContext);

  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading">
      <div className="spinner"></div>
    </div>
  );
};

export default Loading;
