import { PageWidthConstraint as Original } from '@webfox/webfox-ui';
import cls from 'classnames';

const PageWidthConstraint = ({ className, children }) => (
  <div className="flex justify-center">
    <Original className={cls('max-w-[1800px]', className)}>{children}</Original>
  </div>
);

export default PageWidthConstraint;
